"use client";
import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { IoIosArrowUp } from "react-icons/io";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
        controls.start({ opacity: 1, transition: { duration: 0.2 } });
      } else {
        controls.start({ opacity: 0, transition: { duration: 0.2 } });
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, [controls]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <motion.div
      animate={controls}
      initial={{ opacity: 0 }}
      className={`fixed right-5 bottom-5 z-50 cursor-pointer ${
        !isVisible ? "hidden" : ""
      }`}
      onClick={scrollToTop}
    >
      <div className="bg-white text-black p-3 rounded-full">
        <IoIosArrowUp size={30} />
        {/* <h1 className="font-bold">Back to top</h1> */}
      </div>
    </motion.div>
  );
};

export default ScrollToTopButton;
