"use client";
import { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "next/link";
import Image from "next/image";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About Us", href: "/about-us" },
  {
    name: "BH Fitness",
    href: "/bh-fitness/",
    submenu: [
      {
        href: "/bh-fitness",
      },
      {
        name: "Cardio",
        href: "/cardio",
        submenu: [
          {
            name: "Cardio Movemia",
            href: "/bh-fitness/cardio-movemia",
          },
          {
            name: "Cardio Inertia",
            href: "/bh-fitness/cardio-inertia",
          },
        ],
      },
      {
        name: "Strength",
        href: "/services/ecommerce-website-design",
        submenu: [
          {
            name: "Movemia Strength",
            href: "/bh-fitness/movemia-strength",
          },
          {
            name: "Inertia Strength",
            href: "/bh-fitness/inertia-strength",
          },
        ],
      },
      {
        name: "Plate Loaded",
        href: "/bh-fitness/plate-loaded",
      },
      {
        name: "Functional Training",
        href: "/bh-fitness/functional-training",
      },
      {
        name: "Indoor Cycling",
        href: "/bh-fitness/indoor-cycling",
      },
      {
        name: "Racks & Benches",
        href: "/bh-fitness/racks-benches",
      },
      {
        name: "HIIT",
        href: "/bh-fitness/hiit",
      },
      {
        name: "Smart Bike",
        href: "/bh-fitness/smart-bike",
      },
    ],
  },
  { name: "Ciclotte", href: "/ciclotte" },
  {
    name: "Cybex",
    href: null,
    submenu: [
      { name: "View details", href: "/cybex" },
      { name: "V Series Cardio", href: "/cybex/v-series-cardio" },
      { name: "R Series Cardio", href: "/cybex/r-series-cardio" },
      {
        name: "Ion Series - Single Stations",
        href: "/cybex/Ion-series-single-stations",
      },
      {
        name: "Ion Series - Dual Stations",
        href: "/cybex/Ion-series-dual-stations",
      },
      { name: "Prestige Series", href: "/cybex/prestige-serie" },
      { name: "Eagle Series", href: "/cybex/eagle-series" },
      { name: "Functional Trainer", href: "/cybex/functional-trainer" },
      {
        name: "Ion Series - Benches & Racks",
        href: "/cybex/ion-series-benches-racks",
      },
      {
        name: "Prestige Series - Benches & Racks",
        href: "/cybex/prestige-series-benches-racks",
      },
      {
        name: "Prestige Series - Plate Loaded",
        href: "/cybex/prestige-series-plate-loaded",
      },
      { name: "Power Play", href: "/cybex/power-play" },
    ],
  },
  { name: "Jordan", href: "https://www.jordanfitness.com/" },
  { name: "Bendispilates", href: "/bendispilates" },
  { name: "Services", href: "/services" },
  { name: "Contact Us", href: "/contact-us/" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = () => {
  const [openSubmenu, setOpenSubmenu] = useState(null);
  const [openSubsubmenu, setOpenSubsubmenu] = useState(null);

  const handleSubmenuToggle = (index) => {
    setOpenSubmenu(openSubmenu === index ? null : index);
    setOpenSubsubmenu(null); // Close sub-submenu when opening a new submenu
  };

  const handleSubsubmenuToggle = (index) => {
    setOpenSubsubmenu(openSubsubmenu === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".submenu")) {
      setOpenSubmenu(null);
      setOpenSubsubmenu(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLinkClick = (close) => {
    setOpenSubmenu(null);
    setOpenSubsubmenu(null);
    close(); // Close the Disclosure panel
  };

  return (
    <header className="sticky top-0 z-[999] w-full transition-colors duration-300 font-bold tracking-wider bg-black/20 font-sans">
      <Disclosure as="nav" className="bg-black">
        {({ open, close }) => (
          <>
            <div className="max-w-[85rem] mx-auto px-2 ">
              <div className="relative flex items-center justify-between h-24">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0">
                    <Link href="/">
                      <Image
                        src="/images/logo.png"
                        width={100}
                        height={40}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="hidden sm:block sm:ml-6 flex-1 self-center">
                    <div className="flex space-x-4 justify-end">
                      {navigation.map((item, index) =>
                        item.submenu ? (
                          <div key={item.name} className="relative submenu">
                            <button
                              onClick={() => handleSubmenuToggle(index)}
                              className="py-2 uppercase px-3 text-white hover:text-gray-400 flex items-center"
                            >
                              {item.name}
                              <ChevronDownIcon
                                className={`ml-2 h-5 w-5 transition-transform ${
                                  openSubmenu === index
                                    ? "transform rotate-180"
                                    : ""
                                }`}
                              />
                            </button>
                            {openSubmenu === index && (
                              <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-1 w-72">
                                {item.submenu.map((subitem, subindex) =>
                                  subitem.submenu ? (
                                    <div
                                      key={subitem.name}
                                      className="relative"
                                    >
                                      <button
                                        onClick={() =>
                                          handleSubsubmenuToggle(subindex)
                                        }
                                        className="py-2 uppercase px-3 text-black hover:text-gray-400 w-full text-left flex items-center"
                                      >
                                        {subitem.name}
                                        <ChevronDownIcon
                                          className={`ml-2 h-5 w-5 transition-transform ${
                                            openSubsubmenu === subindex
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                      {openSubsubmenu === subindex && (
                                        <div className="absolute z-10 bg-white shadow-lg rounded-md py-2 mt-1 w-56 left-full top-0">
                                          {subitem.submenu.map((subsubitem) => (
                                            <Link
                                              key={subsubitem.name}
                                              href={subsubitem.href}
                                              className="block py-2 uppercase px-3 text-black hover:text-gray-400"
                                              onClick={() =>
                                                handleLinkClick(close)
                                              }
                                            >
                                              {subsubitem.name}
                                            </Link>
                                          ))}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <Link
                                      key={subitem.name}
                                      href={subitem.href}
                                      className="block py-2 uppercase px-3 text-black hover:text-gray-400"
                                      onClick={() => handleLinkClick(close)}
                                    >
                                      {subitem.name}
                                    </Link>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link
                            key={item.name}
                            href={item.href}
                            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
                            onClick={() => handleLinkClick(close)}
                          >
                            {item.name}
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, index) =>
                  item.submenu ? (
                    <Disclosure key={item.name} as="div">
                      {({ open: submenuOpen }) => (
                        <>
                          <Disclosure.Button
                            as="button"
                            className="text-white px-3 py-2 rounded-md text-sm font-medium w-full flex items-center justify-between"
                            onClick={() => handleSubmenuToggle(index)}
                          >
                            {item.name}
                            <ChevronDownIcon
                              className={`h-5 w-5 transition-transform ${
                                openSubmenu === index
                                  ? "transform rotate-180"
                                  : ""
                              }`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel
                            className={`space-y-1 ${
                              submenuOpen ? "block" : "hidden"
                            }`}
                          >
                            {item.submenu.map((subitem, subindex) =>
                              subitem.submenu ? (
                                <Disclosure key={subitem.name} as="div">
                                  {({ open: subsubmenuOpen }) => (
                                    <>
                                      <Disclosure.Button
                                        as="button"
                                        className="px-4 py-2 text-sm text-white hover:text-gray-100 w-full flex items-center justify-between"
                                        onClick={() =>
                                          handleSubsubmenuToggle(subindex)
                                        }
                                      >
                                        {subitem.name}
                                        <ChevronDownIcon
                                          className={`h-5 w-5 transition-transform ${
                                            openSubsubmenu === subindex
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel
                                        className={`space-y-1 ${
                                          subsubmenuOpen ? "block" : "hidden"
                                        }`}
                                      >
                                        {subitem.submenu.map((subsubitem) => (
                                          <Link
                                            key={subsubitem.name}
                                            href={subsubitem.href}
                                            className="block px-4 py-2 text-sm text-white hover:bg-gray-100"
                                            onClick={() =>
                                              handleLinkClick(close)
                                            }
                                          >
                                            {subsubitem.name}
                                          </Link>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              ) : (
                                <Link
                                  key={subitem.name}
                                  href={subitem.href}
                                  className="block px-4 py-2 text-sm text-white hover:bg-gray-100"
                                  onClick={() => handleLinkClick(close)}
                                >
                                  {subitem.name}
                                </Link>
                              )
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ) : (
                    <Link
                      key={item.name}
                      href={item.href}
                      className="block text-white px-3 py-2 rounded-md text-sm font-medium"
                      onClick={() => handleLinkClick(close)}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </header>
  );
};

export default Header;
