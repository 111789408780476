import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/aspirefitness/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/aspirefitness/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/aspirefitness/next.js/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AOSInit"] */ "/home/shankesh/webdesign/aspirefitness/next.js/src/components/aos.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/aspirefitness/next.js/src/components/Floting.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/shankesh/webdesign/aspirefitness/next.js/src/components/Header.js");
;
import(/* webpackMode: "eager" */ "/home/shankesh/webdesign/aspirefitness/next.js/src/components/Header2.js");
