"use client";
import Image from "next/image";
import React, { useState, useEffect, useRef } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const [isBHFitnessOpen, setIsBHFitnessOpen] = useState(false);
  const [isCardioOpen, setIsCardioOpen] = useState(false);
  const [isStrengthOpen, setIsStrengthOpen] = useState(false);
  const [isCybexOpen, setIsCybexOpen] = useState(false);
  const [isCybexCardioOpen, setIsCybexCardioOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const servicesRef = useRef(null);
  const bhFitnessRef = useRef(null);
  const cardioRef = useRef(null);
  const strengthRef = useRef(null);
  const cybexRef = useRef(null);
  const cybexCardioRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (servicesRef.current && !servicesRef.current.contains(event.target)) {
        setIsServicesOpen(false);
      }
      if (
        bhFitnessRef.current &&
        !bhFitnessRef.current.contains(event.target)
      ) {
        setIsBHFitnessOpen(false);
      }
      if (cardioRef.current && !cardioRef.current.contains(event.target)) {
        setIsCardioOpen(false);
      }
      if (strengthRef.current && !strengthRef.current.contains(event.target)) {
        setIsStrengthOpen(false);
      }
      if (cybexRef.current && !cybexRef.current.contains(event.target)) {
        setIsCybexOpen(false);
      }
      if (
        cybexCardioRef.current &&
        !cybexCardioRef.current.contains(event.target)
      ) {
        setIsCybexCardioOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    isServicesOpen,
    isBHFitnessOpen,
    isCardioOpen,
    isStrengthOpen,
    isCybexOpen,
    isCybexCardioOpen,
  ]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleServices = () => {
    setIsServicesOpen(!isServicesOpen);
  };

  const toggleBHFitness = () => {
    setIsBHFitnessOpen(!isBHFitnessOpen);
  };

  const toggleCardio = () => {
    setIsCardioOpen(!isCardioOpen);
  };

  const toggleStrength = () => {
    setIsStrengthOpen(!isStrengthOpen);
  };

  const toggleCybex = () => {
    setIsCybexOpen(!isCybexOpen);
  };

  const toggleCybexCardio = () => {
    setIsCybexCardioOpen(!isCybexCardioOpen);
  };

  return (
    <header
      className={`sticky top-0 z-[999] w-full py-4 transition-colors duration-300 font-bold tracking-wider bg-black ${
        isScrolled ? "backdrop-blur-xl" : "bg-black"
      }`}
    >
      <nav
        className="max-w-[85rem] w-full mx-auto px-4 sm:flex sm:items-center sm:justify-between"
        aria-label="Global"
      >
        <div className="flex items-center justify-between">
          <a className="flex-none text-xl font-semibold text-white" href="/">
            <Image src="/images/logo.png" width={100} height={40} alt="Logo" />
          </a>
          <button
            onClick={toggleMenu}
            className="sm:hidden text-white focus:outline-none"
          >
            {isMenuOpen ? (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            ) : (
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line x1="3" y1="12" x2="21" y2="12" />
                <line x1="3" y1="6" x2="21" y2="6" />
                <line x1="3" y1="18" x2="21" y2="18" />
              </svg>
            )}
          </button>
        </div>
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } sm:flex sm:items-center sm:space-x-6 w-full sm:w-auto`}
        >
          <a
            href="/"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            Home
          </a>
          <a
            href="/about-us"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            About Us
          </a>
          <div className="relative" ref={bhFitnessRef}>
            <button
              onClick={toggleBHFitness}
              className="block uppercase py-2 px-3 text-white hover:text-gray-400"
            >
              BH Fitness
            </button>

            {isBHFitnessOpen && (
              <div className="absolute left-0 mt-2 w-60 bg-white shadow-lg rounded-md">
                <div className="relative" ref={cardioRef}>
                  <a
                    href="/bh-fitness/"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    BH Fitness
                  </a>
                  <button
                    onClick={toggleCardio}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Cardio
                  </button>
                  {isCardioOpen && (
                    <div className="absolute left-full top-0 mt-0 w-48 bg-white shadow-lg rounded-md">
                      <a
                        href="/bh-fitness/cardio-movemia"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Cardio Movemia
                      </a>
                      <a
                        href="/bh-fitness/cardio-inertia"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Cardio Inertia
                      </a>
                    </div>
                  )}
                </div>
                <div className="relative" ref={strengthRef}>
                  <button
                    onClick={toggleStrength}
                    className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100"
                  >
                    Strength
                  </button>
                  {isStrengthOpen && (
                    <div className="absolute left-full top-0 mt-0 w-48 bg-white shadow-lg rounded-md">
                      <a
                        href="/bh-fitness/movemia-strength"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Movemia Strength
                      </a>
                      <a
                        href="/bh-fitness/inertia-strength"
                        className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                      >
                        Inertia Strength
                      </a>
                    </div>
                  )}
                </div>
                <a
                  href="/bh-fitness/plate-loaded"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Plate Loaded
                </a>
                <a
                  href="/bh-fitness/functional-training"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Functional Training
                </a>
                <a
                  href="/bh-fitness/indoor-cycling"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Indoor Cycling
                </a>
                <a
                  href="/bh-fitness/racks-benches"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Racks & Benches
                </a>
                <a
                  href="/bh-fitness/hiit"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  HIIT
                </a>
              </div>
            )}
          </div>
          <a
            href="/ciclotte"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            Ciclotte
          </a>

          <div className="relative" ref={cybexRef}>
            <button
              onClick={toggleCybex}
              className="block uppercase py-2 px-3 text-white hover:text-gray-400"
            >
              Cybex
            </button>
            {isCybexOpen && (
              <div className="absolute left-0 mt-2 w-80 bg-white shadow-lg rounded-md">
                <a
                  href="/cybex/"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Cybex
                </a>
                <a
                  href="/cybex/v-series-cardio"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  V Series Cardio
                </a>
                <a
                  href="/cybex/r-series-cardio"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  R Series Cardio
                </a>
                <a
                  href="/cybex/Ion-series-single-stations"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Ion Series - Single Stations
                </a>
                <a
                  href="/cybex/Ion-series-dual-stations"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Ion Series - Dual Stations
                </a>
                <a
                  href="/cybex/prestige-serie"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Prestige Series
                </a>
                <a
                  href="/cybex/eagle-series"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Eagle Series
                </a>
                <a
                  href="/cybex/functional-trainer"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Functional Trainer
                </a>
                <a
                  href="/cybex/ion-series-benches-racks"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Ion Series - Benches & Racks
                </a>
                <a
                  href="/cybex/prestige-series-benches-racks"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Prestige Series - Benches & Racks
                </a>
                <a
                  href="/cybex/prestige-series-plate-loaded"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Prestige Series - Plate Loaded
                </a>
                <a
                  href="/cybex/power-play"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                >
                  Power Play
                </a>
              </div>
            )}
          </div>
          <a
            href="/jordan"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            Jordan
          </a>
          <a
            href="/bendispilates"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            Bendispilates
          </a>
          <a
            href="/services"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            Services
          </a>

          <a
            href="/contact-us"
            className="block py-2 uppercase px-3 text-white hover:text-gray-400"
          >
            Contact Us
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Header;
